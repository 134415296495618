var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.lesson
    ? _c("div", { staticClass: "wrap-lesson", style: _vm.backgroundColor }, [
        _c(
          "div",
          { staticClass: "container" },
          [
            _c("div", { staticClass: "lesson-banner row background-height" }, [
              _c(
                "div",
                {
                  ref: "banner",
                  staticClass: "wrap-banner",
                  style: _vm.bannerBackgroundImage
                },
                [
                  _c("div", { staticClass: "row d-flex__nowrap " }, [
                    _c(
                      "div",
                      {
                        ref: "teachericon",
                        staticClass: "teacher-icon",
                        staticStyle: { padding: "10px" }
                      },
                      [
                        _c("img", {
                          staticClass: "info-icon",
                          attrs: { src: _vm.infoIcon },
                          on: { click: _vm.showTeacherPage }
                        })
                      ]
                    ),
                    _vm.image
                      ? _c(
                          "div",
                          {
                            ref: "lessonImage",
                            staticClass:
                              "lesson-image lesson-image-mr col-md-3 col-sm-3 col-xs-3"
                          },
                          [_c("img", { attrs: { src: _vm.image } })]
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "lesson-title" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.lesson.title,
                              expression: "lesson.title"
                            }
                          ],
                          staticStyle: { position: "relative" },
                          style: {
                            color: _vm.lesson.color,
                            textShadow: " 2px 2px rgba(0,0,0,0.4)",
                            top: _vm.lesson.titlePositionTop + "px",
                            paddingLeft: _vm.lesson.titlePositionLeft + "px",
                            maxWidth: _vm.lessonTitleWidth + "px"
                          }
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.lesson.title) +
                              "\n            "
                          )
                        ]
                      )
                    ])
                  ])
                ]
              )
            ]),
            _vm.components.length == 0
              ? _c("span", { staticClass: "centered" }, [
                  _vm._v(_vm._s(_vm.$t("noContentToDisplayMessage")))
                ])
              : [
                  _c(
                    "div",
                    {
                      staticClass:
                        "lesson-container flex-container column-direction-skill-lesson"
                    },
                    _vm._l(_vm.components, function(component) {
                      return _c(_vm.getComponentData(component).type, {
                        key: _vm.getComponentData(component).id,
                        tag: "component",
                        attrs: { content: _vm.getComponentData(component) }
                      })
                    })
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "col-md-12 d-end pt-30",
                      staticStyle: { padding: "0px" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "controls-section flex-container" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn round primary",
                              on: { click: _vm.redirectToLessons }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.btnText) +
                                  "\n          "
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ]
          ],
          2
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }