<template>
  <div
    class="
      lesson-container
      flex-container
      column-direction-skill-lesson
      lesson-assessment-group-root
    "
    :style="{ order: content.order }"
  >
    <div
      :id="'assessmentGroup-' + content.id"
      class="assesment__group"
      style="margin-bottom: 50px"
    >
      <component
        v-for="component in content.components.paragraphs"
        :is="component.type"
        :key="component.id"
        :content="component"
      />
      <div class="quesions-group-outer" v-if="getQuestions.length > 0">
        <!-- <div class="components-loop" v-for="component in content.components.questions" :key="component.id">
             <component :is="component.type" :content="component"></component>
          </div> -->
        <keep-alive>
          <component
            :is="currentComponentType"
            :content="currentComponentData"
          ></component>
        </keep-alive>

        <div class="row between" style="margin: 0px">
          <button
            class="btn round primary back"
            @click="goBack"
            :class="isBackButtonDisabled ? 'secondary ' : 'primary'"
            :style="0 == currentIndex ? { opacity: 0 } : { opacity: 1 }"
          >
            {{ $t("back") }}
          </button>
          <!-- <span v-for="(item, i) in getQuestions" :key="i">
             <button class="btn round primary back" @click="submitAssessmetGroup" :disabled="item.isDisabled" :class="item.isDisabled ?'secondary cursor-none': 'primary'">
                {{ $t("submit") }}
        </button> 
        </span> -->
          <!-- <button class="btn round primary back" @click="submitAssessmetGroup" v-if="isPreviewMode">
                {{ $t("submit") }}
        </button>  -->

          <div class="pagination row">
            <div
              v-for="(item, index) in content.assessmentPagerStatus"
              :key="index"
            >
              <!-- <span v-if="item.status == null">{{index+1}}</span> -->

              <!-- <div class="item-pager" v-if="item.status == 0" :class="currentIndex == index ? 'active': ''">
                        <img src="../../assets/BackOfficeAssets/notattempted.svg" alt="">
                      </div>
                      <div  class="item-pager" v-if="item.status == 1" :class="currentIndex == index ? 'active': ''">
                        <img src="../../assets/BackOfficeAssets/attended-question.svg" alt="">
                      </div>
                      <div  class="item-pager" v-if="item.status == 3" :class="currentIndex == index ? 'active': ''"  v-tooltip.top="'You Attended this Question'">
                        <img src="../../assets/BackOfficeAssets/attended-question.png" alt="">
                      </div> -->

              <div
                class="item-pager"
                :class="currentIndex == index ? 'active' : ''"
              >
                <div v-if="item.status == 0 && currentIndex != index">
                  <img
                    src="../../assets/BackOfficeAssets/notattempted.svg"
                    alt=""
                  />
                </div>
                <div v-if="item.status == 1">
                  <img
                    src="../../assets/BackOfficeAssets/attended-question.png"
                    alt=""
                  />
                </div>
                <!-- <div v-if="item.status == 3"  v-tooltip.top="'You Attended this Question'">
                        <img src="../../assets/BackOfficeAssets/attended-question.png" alt="">
                      </div>  -->
                <div v-else-if="item.status == 0 && currentIndex == index">
                  <img src="../../assets/BackOfficeAssets/active.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
          <button
            class="btn round primary back"
            @click="goNext"
            :disabled="isNextButtonDisabled"
            :class="isNextButtonDisabled ? 'secondary ' : 'primary'"
            :style="
              getQuestionMaxLength != currentIndex
                ? { opacity: 1 }
                : { opacity: 0 }
            "
          >
            {{ $t("next") }}
          </button>
        </div>
        <div class="row pagination center" style="line-height: 40px">
          <div class="center-submit"></div>
        </div>
        <modal
          v-if="showModal"
          :click-handler="completeAssessment"
          text-button="OK"
          @close="showModal = false"
        >
          <div class="message">
            <div>{{ submitMessage }}</div>
          </div>
        </modal>
      </div>
      <!-- <component
          v-for="component in content.components.questions"
          :is="component.type"
          :key="component.id"
          :content="component"
        /> -->

      <div
        class="footer static-footer"
        v-if="false"
        style="position: fixed; width: 100%; bottom: 0px; left: 0px; z-index: 9"
      >
        <FooterPagination
          :questions="content.assessmentPagerStatus"
          :grouptype="content.groupHeaderType"
          :content="content"
        />
      </div>
    </div>
    <!-- <div style="min-height: 175px">

    </div> -->
  </div>
</template>
<script>
// import AuthenticationService from "@/services/AuthenticationService.js";
// import { Role } from "@/utils/Roles.js";
import LessonParagraph from "@/components/Lesson/LessonParagraph.vue";
import LessonTextContent from "@/components/Lesson/LessonTextContent.vue";
import VideoContent from "@/components/Lesson/VideoContent.vue";
import LessonCard from "@/components/Lesson/LessonCard.vue";
import SectionTitle from "@/components/Lesson/SectionTitle.vue";
import InteractiveMap from "@/components/Lesson/InteractiveMap.vue";
import InteractiveLesson from "@/components/Lesson/InteractiveLesson.vue";
import GlobalConnections from "@/components/Lesson/GlobalConnections.vue";
import LessonAssessmentGroup from "@/components/Lesson/LessonAssessmentGroup.vue";
import EssentialQuestion from "@/common/EssentialQuestion.vue";
import OnlyImages from "@/components/Lesson/OnlyImages.vue";
import MultipleChoiceSection from "@/components/assessment/MultipleChoiceSection.vue";
import EssaySection from "@/components/assessment/EssaySection.vue";
import WrittenSection from "@/components/assessment/WrittenSection.vue";
import VennDiagramSection from "@/components/assessment/VennDiagramSection.vue";
import AudioToggle from "@/common/AudioToggle.vue";
import Modal from "@/common/Modal.vue";
import FooterPagination from "@/components/assessment/FooterPagination.vue";
// import { Status } from "@/utils/Status.js";
import AssessmentScoreMixin from "@/common/AssessmentScoreMixin.js";
export default {
  name: "LessonAssessmentGroup",
  mixins: [AssessmentScoreMixin],
  props: {
    content: Object,
  },
  components: {
    AudioToggle,
    "multiple-choice": MultipleChoiceSection,
    essay: EssaySection,
    written: WrittenSection,
    "venn-diagram": VennDiagramSection,
    Modal,
    paragraph: LessonParagraph,
    card: LessonCard,
    "section-title": SectionTitle,
    "text-content": LessonTextContent,
    InteractiveMap,
    InteractiveLesson,
    VideoContent,
    EssentialQuestion,
    "only-images": OnlyImages,
    GlobalConnections,
    LessonAssessmentGroup,
    FooterPagination,
  },
  data() {
    return {
      isFooter: false,
      hasQuestions: false,
      currentIndex: 0,
      activeBox: null,
      showModal: false,
      submitMessage: "",
      currentQuestion: null,
    };
  },
  methods: {
    getComponentData(component) {
      // console.log(component , "data")
      // return component && component.paragraph
      //   ? component
      //   : component.question ? component.question : component;
      return component;
    },
    isScrolledIntoView(el) {
      var rect = el.getBoundingClientRect();
      var elemTop = rect.top;
      var elemBottom = rect.bottom;
      // Only completely visible elements return true:
      // var isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);

      // Partially visible elements return true:
      var isVisible = elemTop < window.outerHeight && elemBottom >= 0;
      return isVisible;
    },
    handleScroll() {
      var element = document.getElementById(
        "assessmentGroup-" + this.content.id
      );
      // Any code to be executed when the window is scrolled
      this.isFooter =
        this.isScrolledIntoView(element) && this.checkHavingQuestions;
    },
    goNext() {
      if (this.currentIndex < this.getQuestionMaxLength) {
        this.currentIndex++;
        //  this.$store.commit('loadCurrentQuestion',this.getQuestions[this.currentIndex])
      }
    },
    goBack() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
        //   this.$store.commit('loadCurrentQuestion',this.getQuestions[this.currentIndex])
      }
    },
    submitAssessmetGroup() {
      this.showModal = true;
      this.submitMessage = this.$t("submitAssessmentMessage");
    },
    completeAssessment() {
      this.showModal = false;
      // this.isLoadingInformation = true
      // this.$store.dispatch('submitAssessmentGroupQuestion',{'lessonId': this.$route.params.lessonId,'assessmentgroupnumber': this.content.id})
      this.$store.commit("submitIndividualQuestion");
    },
  },
  mounted() {
    // this.$store.commit('loadCurrentQuestion',this.getQuestions[this.currentIndex])
    // this.$root.$on('testQuestionSubmit',(payload)=>{
    // })
  },
  created() {
    //  window.addEventListener('scroll', this.handleScroll);
  },
  updated() {},
  destroyed() {
    // window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    checkHavingQuestions() {
      return this.content.assessmentPagerStatus.length ? true : false;
    },
    getQuestions() {
      // console.log(this.content.id,"cont id  ")
      return this.content.components.questions;
    },
    currentComponentType() {
      return this.getQuestions[this.currentIndex].type;
    },
    currentComponentData() {
      return this.getQuestions[this.currentIndex];
    },
    getQuestionMaxLength() {
      return this.getQuestions.length - 1;
    },
    isBackButtonDisabled() {
      return this.currentIndex == 0 ? true : false;
    },
    isNextButtonDisabled() {
      // if(this.currentQuestion){
      // return !this.content.components.questions[this.currentIndex].isSubmitted
      // }
      return this.currentIndex == this.getQuestionMaxLength ? true : false;
    },
    isPreviewMode() {
      return !this.$store.state.isPreviewMode;
    },
    currentButton() {
      return this.getQuestions[this.currentIndex];
    },
    pointer() {
      return this.$store.state.currentQuestionPointer.find(
        (item) => item.id === this.content.id
      );
    },
    checkIsSubmitted() {
      return this.content.isSubmitted;
    },
  },
};
</script>
<style lang="less" scoped>
.item-pager {
  margin: 2px;
  // padding: 3px;
  // border: 1px solid #ededed;
  width: 24px;
  height: 24px;
}
.item-pager.active {
  // background: url(../../assets/BackOfficeAssets/active.svg);
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
}
.static-footer {
  min-height: 55px;
  background-color: #b8b8b8;
}
.between {
  justify-content: space-between;
}
.lesson-assessment-group-root {
  // padding: 10px;
  // box-shadow: 1px 1px 13px 5px #ededed;
  margin-bottom: 10px;
}
.cursor-none {
  cursor: not-allowed;
}
.pagination {
  position: relative;
  top: 8px;
}
.pagination a {
  color: black;
  // padding: 8px 10px;
  text-decoration: none;
  transition: background-color 0.3s;
  // border: 1px solid #ddd;
  font-size: 16px;
  margin: 5px;
  // background-color:gray ;
}
.pagination a.active {
  background-color: whitesmoke;
  // color: white;
  border: 1px solid gray;
  // margin-left: 10px;
}
.center {
  justify-content: center;
}
.center-submit {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}
// .assesment__group {
//   .section-title {
//     max-width: 78.1rem;
//   }
// }
</style>