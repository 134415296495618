<template>
  <div class="wrap-lesson" v-if="lesson" :style="backgroundColor">
    <div class="container">
      <div class="lesson-banner row background-height">
        <div class="wrap-banner" ref="banner" :style="bannerBackgroundImage">
          <div class="row d-flex__nowrap ">
            <div class="teacher-icon" ref="teachericon" style="padding:10px">
              <img class="info-icon" @click="showTeacherPage" :src="infoIcon" />
            </div>
            <div
              class="lesson-image lesson-image-mr col-md-3 col-sm-3 col-xs-3"
              ref="lessonImage"
              v-if="image"
            >
              <img :src="image" />
            </div>
            <div class="lesson-title">
              <div
                v-tooltip="lesson.title"
                :style="{
                  color: lesson.color,
                  textShadow: ' 2px 2px rgba(0,0,0,0.4)',
                  top: lesson.titlePositionTop + 'px',
                  paddingLeft: lesson.titlePositionLeft + 'px',
                  maxWidth: lessonTitleWidth + 'px',
                }"
                style="position: relative"
              >
                {{ lesson.title }}
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="banner-background" :style="backgroundColor"></div> -->

        <!-- <img :src="image" class="lesson-image" /> -->
        <!-- <div class="column">
        <span
          class="lesson-title setPosition"
          v-tooltip="lesson.title"
          :style="{
            color: lesson.color,
            textShadow: ' 2px 2px rgba(0,0,0,0.4)',
            top: lesson.titlePositionTop,
            left: lesson.titlePositionLeft,
          }"
          >{{ lesson.title }}</span
        >
        <essential-question
          v-show="false"
          v-if="essentialQuestionText"
          :key="lesson.essentialQuestion.id"
          :audio="essentialQuestionAudio"
          :message="essentialQuestionText"
        />
      </div> -->
      </div>

      <!-- <div class="lesson-background" :style="backgroundColor"></div> -->

      <span v-if="components.length == 0" class="centered">{{
        $t("noContentToDisplayMessage")
      }}</span>

      <template v-else>
        <div
          class="lesson-container flex-container column-direction-skill-lesson"
        >
          <component
            v-for="component in components"
            :is="getComponentData(component).type"
            :key="getComponentData(component).id"
            :content="getComponentData(component)"
          />
        </div>

        <div class="col-md-12 d-end pt-30" style="padding: 0px;">
          <div class="controls-section flex-container">
            <button class="btn round primary" @click="redirectToLessons">
              {{ btnText }}
            </button>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import AuthenticationService from "@/services/AuthenticationService.js";
import { Role } from "@/utils/Roles.js";
import LessonParagraph from "@/components/Lesson/LessonParagraph.vue";
import LessonTextContent from "@/components/Lesson/LessonTextContent.vue";
import VideoContent from "@/components/Lesson/VideoContent.vue";
import LessonCard from "@/components/Lesson/LessonCard.vue";
import SectionTitle from "@/components/Lesson/SectionTitle.vue";
import InteractiveMap from "@/components/Lesson/InteractiveMap.vue";
import InteractiveLesson from "@/components/Lesson/InteractiveLesson.vue";
import GlobalConnections from "@/components/Lesson/GlobalConnections.vue";
import LessonAssessmentGroup from "@/components/Lesson/LessonAssessmentGroup.vue";
import EssentialQuestion from "@/common/EssentialQuestion.vue";
import OnlyImages from "@/components/Lesson/OnlyImages.vue";
import MultipleChoiceSection from "@/components/assessment/MultipleChoiceSection.vue";
import EssaySection from "@/components/assessment/EssaySection.vue";
import WrittenSection from "@/components/assessment/WrittenSection.vue";
import VennDiagramSection from "@/components/assessment/VennDiagramSection.vue";
import AudioToggle from "@/common/AudioToggle.vue";
import Modal from "@/common/Modal.vue";
// import { Status } from "@/utils/Status.js";
import AssessmentScoreMixin from "@/common/AssessmentScoreMixin.js";
// import LocalData from "@/assets/BackOfficeAssets/dummyLessonData.json"

export default {
  name: "skillLessonLayout",
  mixins: [AssessmentScoreMixin],
  components: {
    AudioToggle,
    "multiple-choice": MultipleChoiceSection,
    essay: EssaySection,
    written: WrittenSection,
    "venn-diagram": VennDiagramSection,
    Modal,
    paragraph: LessonParagraph,
    card: LessonCard,
    "section-title": SectionTitle,
    "text-content": LessonTextContent,
    InteractiveMap,
    InteractiveLesson,
    VideoContent,
    EssentialQuestion,
    "only-images": OnlyImages,
    GlobalConnections,
    LessonAssessmentGroup,
  },
  data() {
    return {
      showModal: false,
      submitMessage: "",
      assessmentSections: [],
      lessonTitleWidth: ''
    };
  },
  methods: {
    getComponentData(component) {
      // console.log(component.question)
      // return component && component.paragraph
      //   ? component.paragraph
      //   : component.question ? component.question : component;
      if (component && component.paragraph) {
        return component.paragraph;
      } else if (component.question) {
        return component.question;
      } else if (component.question == null && component.paragraph == null) {
        //   let orderId = component.order
        //   component.assessmentGroup.order = orderId
        //   component.assessmentGroup.components.map((item)=>{
        //     if(item.question){
        //       item.question.groupHeaderType = component.assessmentGroup.groupHeaderType
        //       item.question.isSubmitted = component.assessmentGroup.isSubmitted
        //       if(item.question.groupHeaderType === 1){
        //          item.question.isSubmitted = false
        //       }else if(item.question.groupHeaderType === 2){
        //            item.question.isSubmitted = component.assessmentGroup.isSubmitted
        //       }

        //     }
        //   })
        return component.assessmentGroup;
      } else {
        return component;
      }
    },

    //For Question Components
    // MoveToNextAssessment() {
    //   let indexOfCurrentSection = this.assessmentSections.indexOf(
    //     this.currentSection
    //   );

    //   if (
    //     indexOfCurrentSection === this.assessmentSections.length - 1 &&
    //     this.isPreviewMode
    //   )
    //     return;

    //   if (
    //     indexOfCurrentSection === this.assessmentSections.length - 1 &&
    //     !this.isAssessmentSubmitted
    //   ) {
    //     this.isAssessmentComplete();
    //     this.showModal = true;
    //     return;
    //   }
    //   if (indexOfCurrentSection === this.assessmentSections.length - 1) return;
    //   this.currentSection = this.assessmentSections[indexOfCurrentSection + 1];
    // },
    // MoveToPreviousAssessment() {
    //   let indexOfCurrentSection = this.assessmentSections.indexOf(
    //     this.currentSection
    //   );
    //   if (indexOfCurrentSection === 0) return;

    //   this.currentSection = this.assessmentSections[indexOfCurrentSection - 1];
    // },
    // completeAssessment() {
    //   this.showModal = false;
    //   if (!this.isAssessmentComplete()) return;
    //   this.updateEssayBeforeSubmit();
    //   this.updateWrittenAnswersBeforeSubmit();
    //   this.$store
    //     .dispatch("setAssessmentCompleted", this.$route.params.lessonId)
    //     .then(() => {
    //       this.$router.push({
    //         name: "Unit",
    //         params: {
    //           ...this.$route.params,
    //         },
    //       });
    //       this.currentSection = "";
    //     })
    //     .catch(() => {
    //       this.$store.commit("addSnackBarNotification", {
    //         message: this.$t("failedToSubmitAssessmentMessage"),
    //       });
    //     });
    // },
    // isVennDiagramComplete() {
    //   let vennDiagram = this.assessment.questions.find(
    //     (e) => e.type === "venn-diagram"
    //   );
    //   if (!vennDiagram) return true;

    //   let emptyElements = vennDiagram.vennDiagramElements.filter(
    //     (e) => e.studentAnswerSet === "empty"
    //   );
    //   return emptyElements.length > 0 ? false : true;
    // },
    // isAssessmentComplete() {
    //   this.submitMessage = this.$t("completeAssessmentMessage");
    //   let multipleChoiceQuestions = this.assessment.questions.filter(
    //     (e) => e.type === "multiple-choice"
    //   );

    //   multipleChoiceQuestions.forEach((e) => {
    //     let studentAnswer = e.choices.find((e) => e.correct);
    //     if (!studentAnswer) return false;
    //   });

    //   let isWrittenAnswerCompleted = this.assessment.questions
    //     .filter((elem) => elem.type === "written")
    //     .every(
    //       (elem) =>
    //         typeof elem.studentAnswer === "string" && elem.studentAnswer != ""
    //     );

    //   if (!isWrittenAnswerCompleted) return false;

    //   if (!this.isVennDiagramComplete()) return false;

    //   let essay = this.assessment.questions.find((e) => e.type === "essay");
    //   if (essay && !essay.studentAnswer) return false;

    //   this.submitMessage = this.$t("submitAssessmentMessage");
    //   return true;
    // },
    // getAssessmentSections() {
    //   let sections = [];
    //   this.assessment.questions.map((e) => {
    //     sections.push(`${e.type}-section`);
    //   });
    //   this.assessmentSections = sections.filter(this.onlyUnique);
    //   if (!this.currentSection)
    //     this.currentSection = this.assessmentSections[0];
    // },
    // onlyUnique(value, index, self) {
    //   return self.indexOf(value) === index;
    // },
    // updateEssayBeforeSubmit() {
    //   let essay = this.assessment.questions.find((e) => e.type === "essay");
    //   if (!essay) return;
    //   this.$store.dispatch("updateEssayStudentAnswer", {
    //     assessmentId: this.assessment.id,
    //     questionId: essay.id,
    //     essayText: essay.studentAnswer,
    //   });
    // },
    // updateWrittenAnswersBeforeSubmit() {
    //   let questions = this.assessment.questions.filter(
    //     (e) => e.type === "written"
    //   );
    //   questions.forEach((question) => {
    //     this.$store.dispatch("updateWrittenStudentAnswer", {
    //       assessmentId: this.assessment.id,
    //       questionId: question.id,
    //       writtenText: question.studentAnswer,
    //     });
    //   });
    // },
    // getAssessmentScore() {
    //   let score = this.getScore(this.assessment.questions);
    //   let correctAnswers = this.getCorrectAnswers(this.assessment.questions);
    //   return `Score ${score}% (${correctAnswers}/${this.assessment.questions.length})`;
    // },
    // loadAssessment() {
    //   this.isLoadingInformation = true;
    //   this.$store.commit("loadAssessment", null);
    //   this.$store
    //     .dispatch("loadAssessment", this.$route.params.lessonId)
    //     .then(() => {
    //       this.$store.commit("sortAssessmentBuilderComponents", {
    //         targetCollectionName: "assessment",
    //         getInnerCollectionFunction: (obj) => obj.questions,
    //         setInnerCollectionFunction: (obj, collection) => {
    //           obj.questions = collection;
    //         },
    //       });
    //       this.getAssessmentSections();
    //       this.isLoadingInformation = false;
    //       // if (!this.currentSection)
    //       this.currentSection = this.assessmentSections[0];
    //     })
    //     .catch(() => {
    //       this.isLoadingInformation = false;
    //       this.$store.commit("addSnackBarNotification", {
    //         message: this.$t("failedToLoadAssessmentMessage"),
    //         dismissable: true,
    //       });
    //       this.$router.push({
    //         name: "Unit",
    //         params: {
    //           ...this.$route.params,
    //         },
    //       });
    //     });
    // },
    // goToTeacherPage() {
    //   if (this.$store.state.currentUnit && this.$store.state.currentLesson)
    //     this.$router.push({
    //       name: "TeacherPageLesson",
    //       params: {
    //         unitId: this.$store.state.currentUnit.id,
    //         lessonId: this.$store.state.currentLesson.id,
    //         teacherPageId: this.$store.state.currentLesson.skillsId,
    //       },
    //       query: {
    //         ...(this.$store.state.currentLesson.title && {
    //           title: this.$store.state.currentLesson.title,
    //         }),
    //       },
    //     });
    // },

    //For Paragraph Components
    getImage(image) {
      if (image === 0 || !image) return;
      return `${process.env.VUE_APP_CDN}images/${image}`;
    },
    showTeacherPage() {
      if (this.$store.state.isPreviewModeActive) return;
      this.$router.push({
        name: "TeacherPageLesson",
        params: {
          teacherPageId: this.lesson.skillsId,
          lessonId: this.lesson.id,
          unitId: this.$route.params.unitId,
        },
        query: {
          title: this.lesson.title,
        },
      });
    },
    goToAssessment() {
      this.$router.push({
        name: "Assessment",
        params: {
          ...this.$route.params,
        },
      });
    },
    goToLessons() {
      this.$router.push({ name: "Unit", params: { unitId: this.unit.id } });
      this.setLessonCompleted();
      window.scrollTo(0, 0);
    },
    goToNextLesson() {
      let lessons = this.$store.state.currentUnit.lessons;
      let nextLesson = lessons.find((e) => e.number === this.lesson.number + 1);
      if (!nextLesson) {
        this.$router.push({
          name: "Unit",
          params: { unitId: this.$route.params.unitId },
        });
        return;
      }
      this.setLessonCompleted();
      this.$router.push({
        name: "Lesson",
        params: {
          unitId: this.$route.params.unitId,
          lessonId: nextLesson.id,
        },
      });
    },
    redirectToLessons() {
      if (this.$store.state.isPreviewModeActive) {
        this.goToLessons();
        return;
      }
      !this.lesson.hasAssessment ? this.goToAssessment() : this.goToLessons();
    },
    setLessonCompleted() {
      let userRole = AuthenticationService.getProfile().roleName;
      if (Role.Student.name === userRole) {
        this.$store.dispatch("setLessonCompleted", this.lesson);
      }
    },
    getLessonTitleWidth() {
      // var tIconWidth = this.$refs.teachericon.clientWidth;
      // var lessonImageWidth = this.$refs.lessonImage.clientWidth;
      // var calWidth = tIconWidth + lessonImageWidth;
      // var bannerWidth = this.$refs.banner.clientWidth;
      // this.lessonTitleWidth = bannerWidth - calWidth;
    },
  },
  updated() {
    setTimeout(() => {
      this.getLessonTitleWidth();
    }, 1000);
    //  this.$store.commit("loadLessonAssessementGroupComponents",this.$store.state.currentLesson.components)
  },
  computed: {
    unit() {
      return this.$store.state.currentUnit;
    },
    lesson() {
      return this.$store.state.currentLesson;
      // return LocalData;
    },
    image() {
      return this.getImage(this.lesson.imageName);
    },
    bannerBackgroundImage() {
      return {
        backgroundImage: `url(${this.getImage(
          this.lesson.headerBackgroundName
        )})`,
      };
    },
    components() {
      // console.log(this.lesson.components)
      return this.lesson.components;
      // return LocalData;
    },
    infoIcon() {
      return require("@/assets/icons/ic_info.svg");
    },
    backgroundColor() {
      return {
        backgroundColor: this.lesson.background || "",
        opacity: this.lesson.opacity / 100 || 1,
      };
    },
    essentialQuestionText() {
      if (!this.lesson || !this.lesson.essentialQuestion) return null;
      let essentialQuestionText = this.lesson.essentialQuestion.question;
      return essentialQuestionText[this.locale].length > 0
        ? essentialQuestionText
        : null;
    },
    essentialQuestionAudio() {
      if (!this.lesson.essentialQuestion) return null;
      return this.lesson.essentialQuestion.audio;
    },
    btnText() {
      return this.$t("lessons");
    },
    isReadButtonClicked() {
      return this.$store.state.isMovedToLessonStory;
    },
  },
  created() {
    // this.loadAssessment();
    if (!this.lesson || this.lesson.id != this.$route.params.lessonId) {
      this.isLoadingInformation = true;
      this.$store.commit("loadLesson", null);
      this.$store
        .dispatch("loadLesson", this.$route.params.lessonId)
        .finally(() => {
          this.isLoadingInformation = false;
        });
    }
  },
  watch: {
    $route: function (val) {
      if (val.query.refresh) {
        // this.loadAssessment();
        this.$router.push({
          name: this.$route.name,
          params: this.$route.params,
        });
      }
    },
  },
  mounted() {
    this.startTrackingPage({ sectionName: "Lessons" });
    this.$store.commit("setAnalyzedManagementSection", "Lessons");   
  },
  beforeDestroy() {
    if (this.lesson) this.setLessonCompleted();
    this.$store.commit("setAnalyzedManagementSection", "");
    this.stopTrackingPage({ sectionName: "Lessons" });
  }
};
</script>

<style lang="less" scoped>
.column-direction-skill-lesson {
  flex-direction: column;
  // .section-title{
  //   max-width: 78.1rem;
  // }
}
// .background-height{
//   // min-height: 86.7vh;
// }
.wrap-lesson {
  font-family: "Roboto", sans-serif !important;
  position: relative;
  justify-content: center;
  margin: 0 auto;
  z-index: 0;
  margin-top: 104px;
  height: calc(100% - 104px);
  background-color: white;
  padding-bottom: 30px;
  .wrap-banner {
    min-height: 242px;
    width: 100%;
    max-height: 250px;
    padding: 15px;
    position: relative;
    // background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 30px;
    .teacher-icon {
      text-align: center;
      padding-top: 15px;
      padding-left: 15px;
    }
    .lesson-image {
      img {
        max-width: 100%;
        max-height: 244px;
      }
    }
    .lesson-title {
      color: #3a4dfd;
      font-size: 40px;
      font-weight: 600;
      letter-spacing: 0.25px;
      white-space: normal;
      margin-top: 0px;
    }
  }
  .display-message {
    width: 100%;
    text-align: center;
  }
  .lesson-container {
    width: 100%;
  }
}
.assessment {
  // width: 1280px;
  margin: 0 auto;
  margin-top: 104px;
  height: auto;
  overflow-x: hidden;
  .message {
    height: 143px;
    width: 390px;
    text-align: center;
    span {
      margin: auto;
      white-space: normal;
    }
  }
  .questions-container {
    margin-left: 40px;
    margin-top: 52px;
  }
  .btn {
    margin-top: 16px;
    margin-left: 39px;
    height: 40px;

    &:focus {
      outline: none;
    }
  }
  .btn:last-child {
    float: right;
    margin-right: 39px;
    margin-bottom: 31px;
  }
  > .row {
    margin-top: 16px;
    .legend {
      margin-left: auto;
    }
  }
  .assessment-banner {
    width: 1280px;
    height: 250px;
    max-width: 1280px;
    max-height: 250px;
    background-color: #0a1554;
    padding: 16px;

    .info-icon {
      margin-left: 24px;
      margin-top: 16px;
      margin-bottom: auto;
      cursor: pointer;
    }

    .lesson-image {
      margin-left: 60px;
      max-height: 244px;
      max-width: 468px;
    }
    .audio-toggle {
      margin-top: 16px;
    }
    .column {
      margin-left: 40px;
    }
    .assessment-title {
      margin-top: 8px;
      color: #fff;
      min-height: 32px;
      width: 582px;
      font-family: "Montserrat", sans-serif;
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0.25px;
      text-align: left;
      margin-left: 0;
      text-overflow: initial;
      white-space: normal;
      &::first-letter {
        text-transform: capitalize;
      }
    }
    .assessment-directions {
      margin: auto;
      box-sizing: border-box;
      min-height: auto;
      width: 624px;
      background-color: #eaebff;
      padding: 12px 16px;
      font-family: "Roboto", sans-serif;
      font-style: italic;
      font-size: 18px;
      letter-spacing: 0.4px;
      line-height: 24px;
      max-height: 190px;
      span {
        height: 16px;
        width: 70px;
        color: #0a1554;
        text-transform: capitalize;
      }
      p {
        height: auto;
        width: 600px;
        color: #3a4dfd;
        margin-top: 4px;
        overflow-y: auto;
        max-height: 150px;
      }
    }
  }
  .essay-container {
    margin: 29px 40px 0 40px;
    overflow-x: hidden;
  }

  .question-direction {
    position: relative;

    width: 1200px;
    margin: 0px auto;

    p {
      font-family: "Roboto", sans-serif;
      font-style: italic;
      font-size: 18px;
      letter-spacing: 0.4px;
      line-height: 22px;
    }

    .audio-toggle {
      position: absolute;
      top: 44px;
      left: -30px;
    }
  }
}
.d-end {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 48px;
}
.pt-50 {
  padding-top: 30px;
}
.d-flex__nowrap{
  display: flex;
  flex-wrap: nowrap;
}
</style>