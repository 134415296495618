var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "\n    lesson-container\n    flex-container\n    column-direction-skill-lesson\n    lesson-assessment-group-root\n  ",
      style: { order: _vm.content.order }
    },
    [
      _c(
        "div",
        {
          staticClass: "assesment__group",
          staticStyle: { "margin-bottom": "50px" },
          attrs: { id: "assessmentGroup-" + _vm.content.id }
        },
        [
          _vm._l(_vm.content.components.paragraphs, function(component) {
            return _c(component.type, {
              key: component.id,
              tag: "component",
              attrs: { content: component }
            })
          }),
          _vm.getQuestions.length > 0
            ? _c(
                "div",
                { staticClass: "quesions-group-outer" },
                [
                  _c(
                    "keep-alive",
                    [
                      _c(_vm.currentComponentType, {
                        tag: "component",
                        attrs: { content: _vm.currentComponentData }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "row between",
                      staticStyle: { margin: "0px" }
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn round primary back",
                          class: _vm.isBackButtonDisabled
                            ? "secondary "
                            : "primary",
                          style:
                            0 == _vm.currentIndex
                              ? { opacity: 0 }
                              : { opacity: 1 },
                          on: { click: _vm.goBack }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("back")) +
                              "\n        "
                          )
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "pagination row" },
                        _vm._l(_vm.content.assessmentPagerStatus, function(
                          item,
                          index
                        ) {
                          return _c("div", { key: index }, [
                            _c(
                              "div",
                              {
                                staticClass: "item-pager",
                                class: _vm.currentIndex == index ? "active" : ""
                              },
                              [
                                item.status == 0 && _vm.currentIndex != index
                                  ? _c("div", [
                                      _c("img", {
                                        attrs: {
                                          src: require("../../assets/BackOfficeAssets/notattempted.svg"),
                                          alt: ""
                                        }
                                      })
                                    ])
                                  : _vm._e(),
                                item.status == 1
                                  ? _c("div", [
                                      _c("img", {
                                        attrs: {
                                          src: require("../../assets/BackOfficeAssets/attended-question.png"),
                                          alt: ""
                                        }
                                      })
                                    ])
                                  : item.status == 0 &&
                                    _vm.currentIndex == index
                                    ? _c("div", [
                                        _c("img", {
                                          attrs: {
                                            src: require("../../assets/BackOfficeAssets/active.svg"),
                                            alt: ""
                                          }
                                        })
                                      ])
                                    : _vm._e()
                              ]
                            )
                          ])
                        })
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn round primary back",
                          class: _vm.isNextButtonDisabled
                            ? "secondary "
                            : "primary",
                          style:
                            _vm.getQuestionMaxLength != _vm.currentIndex
                              ? { opacity: 1 }
                              : { opacity: 0 },
                          attrs: { disabled: _vm.isNextButtonDisabled },
                          on: { click: _vm.goNext }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("next")) +
                              "\n        "
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._m(0),
                  _vm.showModal
                    ? _c(
                        "modal",
                        {
                          attrs: {
                            "click-handler": _vm.completeAssessment,
                            "text-button": "OK"
                          },
                          on: {
                            close: function($event) {
                              _vm.showModal = false
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: "message" }, [
                            _c("div", [_vm._v(_vm._s(_vm.submitMessage))])
                          ])
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          false
            ? _c(
                "div",
                {
                  staticClass: "footer static-footer",
                  staticStyle: {
                    position: "fixed",
                    width: "100%",
                    bottom: "0px",
                    left: "0px",
                    "z-index": "9"
                  }
                },
                [
                  _c("FooterPagination", {
                    attrs: {
                      questions: _vm.content.assessmentPagerStatus,
                      grouptype: _vm.content.groupHeaderType,
                      content: _vm.content
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "row pagination center",
        staticStyle: { "line-height": "40px" }
      },
      [_c("div", { staticClass: "center-submit" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }