var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pagination" },
    [
      _c("div", { staticClass: "fotter_btn_pgn" }, [
        _c("div", { staticClass: "row ml-4 footer-btn" }, [
          _c(
            "button",
            {
              staticClass: "btn round primary back",
              attrs: {
                disabled: _vm.activeBox === 0 || _vm.activeBox === null
              },
              on: { click: _vm.goBack }
            },
            [_vm._v("\r\n        " + _vm._s(_vm.$t("previous")) + "\r\n      ")]
          )
        ]),
        _c(
          "ul",
          { staticClass: "footer" },
          [
            _vm._l(_vm.questions, function(item, index) {
              return _c("li", { key: item.id }, [
                _c(
                  "a",
                  {
                    class: { active: _vm.activeBox === index },
                    attrs: { href: _vm.hrefID(item.questionId) },
                    on: {
                      click: function($event) {
                        _vm.navigateToQuestion(item.questionId, $event, index)
                      }
                    }
                  },
                  [
                    item.status == null
                      ? _c("span", [_vm._v(_vm._s(index + 1))])
                      : _c("span", [
                          item.status == 0
                            ? _c("span", [
                                _c("img", {
                                  attrs: {
                                    src: require("../../assets/icons/close.png"),
                                    alt: ""
                                  }
                                })
                              ])
                            : _vm._e(),
                          item.status == 1
                            ? _c("span", [
                                _c("img", {
                                  attrs: {
                                    src: require("../../assets/icons/check-mark.png"),
                                    alt: ""
                                  }
                                })
                              ])
                            : _vm._e(),
                          item.status == 3
                            ? _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip.top",
                                      value: "You Attended this Question",
                                      expression:
                                        "'You Attended this Question'",
                                      modifiers: { top: true }
                                    }
                                  ]
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("../../assets/icons/attendended.png"),
                                      alt: ""
                                    }
                                  })
                                ]
                              )
                            : _vm._e()
                        ])
                  ]
                )
              ])
            }),
            !_vm.content.isSubmitted || _vm.content.groupHeaderType === 1
              ? _c(
                  "button",
                  {
                    staticClass: "btn round primary back",
                    on: { click: _vm.submitAssessmetGroup }
                  },
                  [
                    _vm._v(
                      "\r\n          " +
                        _vm._s(_vm.$t("submit")) +
                        "\r\n        "
                    )
                  ]
                )
              : _vm._e()
          ],
          2
        ),
        _c("div", { staticClass: "row mr-4" }, [
          _c(
            "button",
            {
              staticClass: "btn round primary back",
              attrs: { disabled: _vm.activeBox === _vm.questions.length - 1 },
              on: { click: _vm.goNext }
            },
            [_vm._v("\r\n          " + _vm._s(_vm.$t("next")) + "\r\n        ")]
          )
        ])
      ]),
      _vm._m(0),
      _vm.showModal
        ? _c(
            "modal",
            {
              attrs: {
                "click-handler": _vm.completeAssessment,
                "text-button": "OK"
              },
              on: {
                close: function($event) {
                  _vm.showModal = false
                }
              }
            },
            [
              _c("div", { staticClass: "message" }, [
                _c("div", [_vm._v(_vm._s(_vm.submitMessage))])
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { "text-align": "center", "margin-top": "10px" } },
      [_c("div")]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }