<template>
<div class="pagination">

  <!-- <a href="#">&laquo;</a> -->
  <!-- <a href="#">1</a>
  <a href="#" class="active">2</a>
  <a href="#"  class="active">3</a>
  <a href="#"  class="active">4</a>
  <a href="#"  class="active">5</a> -->
   <!-- <template  :v-for="(question,index) in questions">
      <h1>{{question}}</h1>
  </template> -->
  <!-- <a href="#">&raquo;</a> -->
 
  <div class="fotter_btn_pgn">
    <div class="row ml-4 footer-btn">
      <button class="btn round primary back" :disabled=" activeBox === 0 || activeBox === null " @click="goBack">
        {{ $t("previous") }}
      </button>
    </div>
    <ul  class="footer">
      <li v-for="(item,index) in questions" :key="item.id">
      <a  v-on:click="navigateToQuestion(item.questionId,$event,index)"  :href="hrefID(item.questionId)" :class="{ active: activeBox === index }">
        <span v-if="item.status == null">{{index+1}}</span>
        <span v-else>
            <span v-if="item.status == 0">
              <img src="../../assets/icons/close.png" alt="">
            </span>
            <span v-if="item.status == 1">
              <img src="../../assets/icons/check-mark.png" alt="">
            </span>
            <span v-if="item.status == 3" v-tooltip.top="'You Attended this Question'">
               <img src="../../assets/icons/attendended.png" alt="">
            </span>
        </span>
        </a>
      </li>
      <button class="btn round primary back" @click="submitAssessmetGroup"  v-if="!content.isSubmitted || content.groupHeaderType === 1">
          {{ $t("submit") }}
        </button>     
    </ul>
    <div class="row mr-4">
        <button class="btn round primary back" :disabled="activeBox === questions.length-1" @click="goNext">
          {{ $t("next") }}
        </button>
    </div>
    
    </div>
    <div class="" style="text-align: center;margin-top: 10px;">
       <div >
      
  </div>
  </div>
   <modal
      v-if="showModal"
      :click-handler="completeAssessment"
      text-button="OK"
      @close="showModal = false"
    >
      <div class="message">
        <div>{{ submitMessage }}</div>
      </div>
    </modal>
</div>
</template>

<script>
// $( "a" ).click(function( event ) {
//   console.log("*****")
//   event.preventDefault();
// });
import Modal from "@/common/Modal.vue";
  export default {
    name:'FooterPagination',
    props:['questions','grouptype','content'],
    components:{
      Modal
    },
    data() {
      return {  
        activeBox:null,
        showModal:false,
        submitMessage:""      
      }
    },
    methods:{
      hrefID(questionId){ 
      return `#cmp${questionId}`
      },
      navigateToQuestion: function(questionId,event,index){
        this.activeBox = index
        // console.log("Event",event)
        event.preventDefault();
        var elmnt = document.getElementById(`cmp${questionId}`);
        // console.log(elmnt);
           elmnt.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center'
        });        
      },
      submitAssessmetGroup(){
        this.showModal = true;
         this.submitMessage = this.$t("submitAssessmentMessage");
      },
      completeAssessment(){
          this.showModal = false;
          // this.isLoadingInformation = true
          this.$store.dispatch('submitAssessmentGroupQuestion',{'lessonId': this.$route.params.lessonId,'assessmentgroupnumber': this.content.id})
      },
      goNext(){
            this.activeBox ++;
            this.navigateToQuestion(this.getCurrentQuestionId,event,this.activeBox)
       
      },
      goBack(){
            this.activeBox --
            this.navigateToQuestion(this.getCurrentQuestionId,event,this.activeBox)
      }
    },
    computed: {
      checkGroupType(){
        return this.grouptype == 1 ? true : false
      },
      curentLessonObj(){
        return this.$store.state.currentLesson
      },
      getCurrentQuestionId(){
        return this.questions[this.activeBox].questionId
      }
    }
  }
</script>
<style  lang="less" scoped>
.pagination {
  // display: inline-block;
  // margin-left: 45vw;
  margin-top: 10px;
}

.pagination a {
  color: black;
  padding: 8px 10px;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid #ddd;
  font-size: 16px;
  background-color:gray ;
}

.footer{
    display: flex;
    align-items: center;
    // margin-left: 27vw;
    // margin-bottom: 10px;
    flex-wrap: wrap;
    // width: 500px;
}
.pagination a.active {
  background-color: whitesmoke;
  // color: white;
  border: 1px solid gray;
  // margin-left: 10px;
}
.footer-btn{
  font-size: 16px;
  font-family: 'Roboto',sans-serif;
}
.fotter_btn_pgn{
  display: flex;
  justify-content: space-between;
  padding-inline: 40px;
}
button:disabled{
  cursor: not-allowed;
}
.pagination a:hover:not(.active) {background-color: #ddd;}
</style>